<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import draggable from 'vuedraggable';

  export default {
    name: "BoTopic",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoTopic",
        subCat: {},
        row1: {},
        category: {},
        MrSubCat: {},
        statusTopic: {},
        lenghtTitle:0,
        MrCat:[],
        webSettings:{},
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
      this.filter.viewby = this.webSettings.aws_val
    },
    methods: {
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.data.data,
          type: 'sort'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      editSlide(v) {
        $('#collapseExample').css("display", "flex")
        // if ($("#collapseExample").css("display") == "none") $("#collapseExample").css("display","flex")
        // else $("#collapseExample").hide()
        this.row1 = v;
        this.row1.type = 'update'
      },
      submitFormCat() {
        BOGen.apirest('/' + this.Name, {
          data: this.row1,
          type: 'updateHero'
        }, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.hero-info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.hero-info')
            this.refreshData()
            setTimeout(() => {
              $('#collapseExample').hide()
            }, 2000)
          }
        }, "POST");
      },
      submitView() {
        BOGen.apirest('/' + this.Name, {
          data: this.webSettings,
          type: 'updateView'
        }, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.info')
            this.refreshData()
          }
        }, "POST");
      },
      sort(){
        this.filter.sort = this.filter.sort == 'all' ? '' : 'all'
        if(this.filter.sort == 'all'){
          this.filter.category=''
          this.filter.statusProduct=''
        }
        var query = this.filter
        delete query.page
        this.$router.push({name:this.Name, query: query})
      },
      endDrag() {
        BOGen.apirest('/' + this.Name, { data: this.data.data, type: 'sort' }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      viewBy(v){
        console.log(v.target.value)
      }
    },
    watch: {
      '$route.query'() {
        if(this.$route.params.id){
          this.$set(this.row, 'category', this)
          this.$set(this.row, 'MrSubCat', this)
          this.$set(this.row, 'statusTopic', this)
          this.$set(this.row, 'search', this)
          this.$set(this.row, 'page', this)
          setTimeout(()=>{
            this.row.category = this.$route.query.category
            this.row.MrSubCat = this.$route.query.MrSubCat
            this.row.statusTopic = this.$route.query.statusTopic
            this.row.search = this.$route.query.search
            this.row.page = this.$route.query.page
          },500)
        }
        this.refreshData()
      },
      'filter.category'(v) {
        if (!v) return
        this.search()
      },
      'filter.statusTopic'(v) {
        if (!v) return
        this.search()
      },
      'filter.MrSubCat'(v) {
        if (!v) return
        this.search()
      },
      'row.mh_title'(v){
        this.lenghtTitle = this.row.mh_title.length
      }
    }
  };
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>

    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-header">

            <h5 class="card-title">Category</h5>
          </div>
          <div class="card-body">
            <div class="alert alert-info">
              Image Requirements for Topic Category Banner :
              <ul class="mb-0 mt-2">
                <li><strong>Dimension: </strong><span>560x200px (Background), 150x150px (Icon)</span></li>
                <li><strong>Format: </strong><span>jpeg,jpg,png,svg</span></li>
                <li><strong>Max. Size: </strong><span>2 MB</span></li>
              </ul>
            </div>
            <div class="row">
              <div  class="col-md-2" :id="'dat'+v.mhc_id" v-for="(v,k) in subCat" :key="k">
                <div class="wrap_slider_img text-center"
                  :style="'background-image: url('+uploader(v.mhc_image)+');background-size: cover;'">
                  <img :src="uploader(v.mhc_logo)" class="img-responsive" />
                  <div class="slider_name">
                    <p>{{v.mhc_name}}</p>
                  </div>
                  <a data-toggle="collapse" @click="editSlide(v)" href="javascript:;" class="bullet_edit"><i
                      class="ti-marker-alt"></i></a>
                  <!-- <span class="label label-success" v-if="v.mhc_is_active == 'Y'">Active</span>
                  <span class="label label-danger" v-else>Inactive</span> -->
                </div>
              </div>
            </div>
            <VForm @resp="submitFormCat" method="post">
              <div class="row collapse mt-4" id="collapseExample">
                <div class="col-12 hero-info"></div>
                <div class="col-md-4 mb-3">
                  <BoField name="mhc_name" v-model="row1.mhc_name"></BoField>
                  <BoField name="mhc_logo" mandatory>
                    <Uploader :param="{thumbnail:true}" name="mhc_logo" v-model="row1.mhc_logo" type="healthcatlogo"
                      uploadType="cropping"></Uploader>
                  </BoField>
                  <!-- <BoField name="mhc_is_active">
                    <div class="row">
                      <div class="col-md-2">
                        <radio name="mhc_is_active" v-model="row1.mhc_is_active" option="Y"
                          :attr="validation('mhc_is_active')">Active</radio>
                      </div>
                      <div class="col-md-2">
                        <radio name="mhc_is_active" v-model="row1.mhc_is_active" option="N">Inactive</radio>
                      </div>
                    </div>
                  </BoField> -->
                </div>
                <div class="col-md-8 mb-3">
                  <BoField name="mhc_image" mandatory>
                    <Uploader :param="{thumbnail:true}" name="mhc_image" v-model="row1.mhc_image" type="healthcat"
                      uploadType="cropping"></Uploader>
                  </BoField>
                </div>

                <div class="col-4 mt-3">
                  <button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card">
          <div class="card-header" v-if="!$route.params.id">
            <VForm @resp="search">
              <div class="row">
                <div class="col-sm-1">
                  <h5 class="card-title">Article List</h5>
                </div>
                <div class="col-sm-2">
                    <button class="btn btn-success" type="button" @click="sort()">
                      <i class="fas fa-sort"></i> Sorting All
                    </button>
                </div>
                <div class="col-sm-2">
                  <select2 :options="MrCat" :object="['mht_id','mht_title']" v-model="filter.category">
                    <option value="">-- Category --</option>
                  </select2>
                </div>
                <div class="col-sm-2">
                  <select2 :options="MrSubCat" :object="['mhc_id','mhc_name']" v-model="filter.MrSubCat">
                    <option value="">-- Sub Category --</option>
                  </select2>
                </div>
                <div class="col-sm-2">
                  <select2 :options="statusTopic" v-model="filter.statusTopic">
                    <option value="">-- Status --</option>
                  </select2>
                </div>
                <div class="col-sm-2">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input type="text" v-model="filter.search" v-on:keyup.enter="search" class="form-control"
                        placeholder="Search...">
                      <div class="input-group-append">
                        <button class="btn btn-info" type="button" @click="search()">
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-1">
                  <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                </div>
              </div>
            </VForm>
          </div>
          <div class="card-body">
            <div class="alert alert-info">
              Image Requirements for Article Cover :
              <ul class="mb-0 mt-2">
                <li><strong>Dimension: </strong><span>850x500px</span></li>
                <li><strong>Format: </strong><span>jpeg,jpg,png,svg</span></li>
                <li><strong>Max. Size: </strong><span>2 MB</span></li>
              </ul>
            </div>
            <div class="row" v-if="!$route.params.id">
              <div class="col-12 mb-3">
                <div class="card">
                  <div class="card-header">
                    <h5>View article by</h5>
                  </div>
                  <div class="card-body " style="border: 1px solid #f7f7f7;">
                    <div class="info"></div>
                    <VForm @resp="submitView" class="row">
                      <div class="col-6">
                        <BoField name="aws_val">
                          <div class="row">
                            <radio name="aws_val" v-model="webSettings.aws_val" option="P" :attr="validation('aws_val')">Publish Date</radio>
                            <radio name="aws_val" v-model="webSettings.aws_val" option="S">Sorting</radio>
                          </div>
                        </BoField>
                      </div>
                      <div class="col-6 text-right">
                        <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading" style="margin-top: 3%;">Update <i class="icon-arrow-right14 position-right"></i></button>
                      </div>
                    </VForm>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <draggable v-model="data.data" tag="div" class="row" @end="endDrag">
                  <div class="col-md-3 mb-3" v-for="(v,k) in data.data" :key="k" style="cursor: move;">
                    <div class="item-list collection">
                      <div class="row text-center">
                        <div class="col-md-12">
                          <div class="product-img recipe-cat mb-2">
                            <img :src="uploader(v.mh_image,'250')" alt="prod">
                            <div class="pro-img-overlay" style="cursor: default;">
                              <router-link class="bg-info" :to="{name:Name,params:{id:v.id},query:$route.query}" v-tooltip="'Edit'"><i
                                  class="ti-marker-alt"></i></router-link>
                              <a href="javascript:;" class="bg-danger" @click="deleteItem($event,k)"
                                v-tooltip="'Remove'"><i class="ti-trash"></i></a>
                            </div>
                            <span class="label label-success" v-if="v.mh_is_active=='Y'">Active</span>
                            <span class="label label-danger" v-else>Inactive</span>
                          </div>
                          <small class="text-danger mt-2">{{v.category}}</small>
                          <h5 class="card-title mt-1">{{v.mh_title}}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div v-if="NotFound" class="text-center col-md-12">
                  <h5 class="tc">{{NotFound}}</h5>
                </div>
                <div v-if="data.data===false" class="text-center col-md-12">
                  <LoadingSpinner light></LoadingSpinner>
                </div>
                <div class="pull-right">
                  <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
                </div>
              </div>
            </div>

            <div class="row" v-if="$route.params.id">
              <div class="col-12">
                <VForm @resp="submitForm" method="post">
                  <div class="row mb-3">
                    <div class="col-md-8">
                      <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
                    </div>
                  </div>
                  <div class="info"></div>
                  <div class="row">
                    <div class="col-sm-7">
                      <BoField name="mh_title" v-model="row.mh_title"></BoField>
                      <small class="mb-2 d-inline-block text-info">{{validation('mh_title').maxlength-lenghtTitle}}
                        character remaining </small>
                      <BoField name="mh_cat_id">
                        <select2 name="mh_cat_id" v-bind="validation('mh_cat_id')" :options="MrCategory"
                          :object="['mht_id','mht_title']" v-model="row.mh_cat_id" multiple>
                        </select2>
                      </BoField>
                      <BoField name="mh_sub_cat">
                        <select2 name="mh_sub_cat" v-bind="validation('mh_sub_cat')" :options="MrSubCategory"
                          :object="['mhc_id','mhc_name']" v-model="row.mh_sub_cat" multiple>
                        </select2>
                      </BoField>
                      <BoField name="mh_desc" mandatory>
                        <CKEditor name="mh_desc" v-model="row.mh_desc" v-bind="validation('mh_desc')"></CKEditor>
                      </BoField>

                      <div class="row mb-3">
                        <div class="col-md-8">
                          <h5 class="card-title">Meta Content</h5>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <BoField name="mh_seo_title" :attr="{minlength:'3'}" v-model="row.mh_seo_title" mandatory>
                          </BoField>
                          <BoField name="mh_seo_keyword" mandatory>
                            <TagsInput name="mh_seo_keyword" v-model="row.mh_seo_keyword"
                              :attr="validation('mh_seo_keyword')"></TagsInput>
                          </BoField>
                        </div>
                        <div class="col-sm-12">
                          <BoField name="mh_seo_desc" mandatory>
                            <textarea name="mh_seo_desc" rows="5" :attr="{minlength:'10'}" v-model="row.mh_seo_desc"
                              v-bind="validation('mh_seo_desc')" class="form-control"></textarea>
                          </BoField>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <BoField name="mh_image">
                        <Uploader name="mh_image" type="recipe" uploadType="cropping" :param="{thumbnail:true}"
                          v-model="row.mh_image"></Uploader>
                      </BoField>
                      <BoField name="mh_author" v-model="row.mh_author"></BoField>
                      <BoField name="mh_publish_date">
                        <DatePicker name="mh_publish_date" v-model="row.mh_publish_date"
                          v-bind="validation('mh_publish_date')"></DatePicker>
                      </BoField>
                      <BoField name="mh_related_product">
                        <select2 name="mh_related_product" v-bind="validation('mh_related_product')"
                          :options="MrProduct" :object="['mp_id','mp_title']" v-model="row.mh_related_product" multiple>
                        </select2>
                      </BoField>


                      <div class="row">
                        <div class="col-7">
                          <BoField name="mh_is_active">
                            <div class="row">
                              <radio name="mh_is_active" v-model="row.mh_is_active" option="Y"
                                :attr="validation('mh_is_active')">Active</radio>
                              <radio name="mh_is_active" v-model="row.mh_is_active" option="N">Inactive</radio>
                            </div>
                          </BoField>
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-7">
                          <BoField name="mh_show_mini_health">
                            <div class="row">
                              <radio name="mh_show_mini_health" v-model="row.mh_show_mini_health" option="Y"
                                :attr="validation('mh_show_mini_health')">Yes</radio>
                              <radio name="mh_show_mini_health" v-model="row.mh_show_mini_health" option="N">No</radio>
                            </div>
                          </BoField>
                        </div>
                      </div>
                      <div class="row" v-if="row.mh_show_mini_health == 'Y'">
                        <div class="col-md-12">
                          <div class="card border-info">
                            <div class="card-header bg-default">
                              Mini Health Settings
                            </div>
                            <div class="card-body" style="border: 1px solid #f7f7f7;">
                                 <div class="row" >
                                    <div class="col-6">
                                      <BoField name="mh_show_bmi">
                                        <div class="row">
                                          <radio name="mh_show_bmi" v-model="row.mh_show_bmi" option="Y"
                                            :attr="validation('mh_show_bmi')">Yes</radio>
                                          <radio name="mh_show_bmi" v-model="row.mh_show_bmi" option="N">No</radio>
                                        </div>
                                      </BoField>
                                    </div>
                                    <div class="col-6">
                                      <BoField name="mh_pradiabet">
                                        <div class="row">
                                          <radio name="mh_pradiabet" v-model="row.mh_pradiabet" option="Y"
                                            :attr="validation('mh_pradiabet')">Yes</radio>
                                          <radio name="mh_pradiabet" v-model="row.mh_pradiabet" option="N">No</radio>
                                        </div>
                                      </BoField>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-6">
                                      <BoField name="mh_heart_disease">
                                        <div class="row">
                                          <radio name="mh_heart_disease" v-model="row.mh_heart_disease" option="Y"
                                            :attr="validation('mh_heart_disease')">Yes</radio>
                                          <radio name="mh_heart_disease" v-model="row.mh_heart_disease" option="N">No</radio>
                                        </div>
                                      </BoField>
                                    </div>
                                    <div class="col-6">
                                      <BoField name="mh_food_meter">
                                        <div class="row">
                                          <radio name="mh_food_meter" v-model="row.mh_food_meter" option="Y"
                                            :attr="validation('mh_food_meter')">Yes</radio>
                                          <radio name="mh_food_meter" v-model="row.mh_food_meter" option="N">No</radio>
                                        </div>
                                      </BoField>
                                    </div>
                                  </div>

                                  <div class="row" v-if="row.mh_show_mini_health == 'Y'">
                                    <div class="col-7">
                                      <BoField name="mh_activity">
                                        <div class="row">
                                          <radio name="mh_activity" v-model="row.mh_activity" option="Y"
                                            :attr="validation('mh_activity')">Yes</radio>
                                          <radio name="mh_activity" v-model="row.mh_activity" option="N">No</radio>
                                        </div>
                                      </BoField>
                                    </div>
                                  </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="text-right">
                        <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form
                          <i class="icon-arrow-right14 position-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </VForm>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>